<template>
  <div class="edu-frame" style="background-color: white;">
    <edu-top-header></edu-top-header>
    <div style="border: 1px solid lightgray;background-color: white;width: 95%;margin: 10px auto 10px;position: relative;padding-bottom: 10px;">

      <div style="font-size: 18px;font-weight: bold;display: flex;justify-content: center;padding: 10px 0px;">申请人承诺</div>

      <div style="width: 96%;margin: 0 auto;font-size: 14px;line-height: 28px;">
        1.本人自愿提供资料，并保证资料的真实性；<br/>
        2.本人自愿加入广东省妇女儿童活动中心巾帼故事妈妈志愿队，认可并愿意付出努力共同实现“粤美·巾帼故事妈妈”宗旨与使命，认同并遵守项目服务中的公益免费原则、安全第一原则、价值导向正确原则；<br/>
        3.本人愿意遵守广东省妇女儿童活动中心“粤美·巾帼故事妈妈”志愿者队伍管理办法等相关制度要求；<br/>
        4.本人具备参加志愿服务相应的基本能力和身体素质。<br/>
      </div>
    </div>

    <div style="font-size: 14px;width: calc(95% - 4%);height: 50px;padding: 0px 2%;display: flex;align-items: center;margin: 0 auto 10px;">
      <van-checkbox shape="square" v-model="checked" icon-size="20px">
        <div>本人知悉并同意上述要求</div>
        <div>自愿加入巾帼故事妈妈志愿者队伍</div>
      </van-checkbox>
    </div>

    <div class="button-group" style="margin: 0px auto;width: 95%">
      <van-button size="small" block type="danger" native-type="button" @click="$router.go(-1);" style="margin-right: 1px;">上一步</van-button>
      <van-button :loading="submitting" size="small" block type="danger" @click="toNext" style="margin-left: 1px;">下一步</van-button>
    </div>
  </div>
</template>

<script>
import EduTopHeader from "@/components/VolunteerHeader";
import {Button, Checkbox, DatetimePicker, Field, Form, Popup} from "vant";
export default {
  components: {
    EduTopHeader,
    VanButton: Button,
    VanCheckbox: Checkbox
  },
  data() {
    return {
      checked: false,
      submitting: false
    }
  },
  methods: {
    toNext() {
      if (this.checked == false) {
        this.$dialog.alert({message: "请先阅读并同意上述承诺书。"})
        return
      }

      this.$router.push({ name: 'vregister' })
    }
  }
}
</script>

<style scoped>
.button-group {
  margin: 16px;
  display: flex;
}
</style>
